import React, {Component} from 'react';
import styles from './BuyNow.module.css';

const SmallBoxBuyNow = () => {
return(
    <div>
        <div className={styles.container}>
        <img className={styles.image} src="https://checkout.square.site/uploads/1/3/9/6/139612448/s325809086686251968_p11_i1_w3024.jpeg?width=640" alt="Small Board"/>
            <div className={styles.textContainer}>
                <p className={styles.titleFont}>Small Board</p>
                <p className={styles.priceFont}>$65.00</p>
                <a className={styles.button} target="_blank" data-url="https://checkout.square.site/buy/EC7SK5BIUHPP4I3HPX6DSFBV" href="https://checkout.square.site/buy/EC7SK5BIUHPP4I3HPX6DSFBV">Buy now</a>
            </div>
        </div>
    </div>
)}

export default SmallBoxBuyNow;