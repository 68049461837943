import React from "react"
import Layout from '../containers/Layout/Layout'
import styles from './Seasonal.module.css'
import SuperBowlBuyNow from '../components/BuyNow/MiniBoxBuyNow'
import ValentinesBuyNow from '../components/BuyNow/SmallBoxBuyNow'
import SmallBoxBuyNow from "../components/BuyNow/SmallBoxBuyNow"
import MiniBoxBuyNow from "../components/BuyNow/MiniBoxBuyNow"

const Seasonal = () => {
    return(
        <Layout>
            <div className={styles.Seasonal}>
                <div className={styles.Content}>
                    <div className={styles.Options}>
                        <SmallBoxBuyNow />
                        <MiniBoxBuyNow />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Seasonal;