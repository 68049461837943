import React, {Component} from 'react';
import styles from './BuyNow.module.css';

const MiniBoxBuyNow = () => {
return(
    <div>
        <div className={styles.container}>
        <img className={styles.image} src="https://checkout.square.site/uploads/1/3/9/6/139612448/s325809086686251968_p10_i1_w2213.jpeg?width=640" alt="Mini Box"/>
            <div className={styles.textContainer}>
                <p className={styles.titleFont}>Mini Box</p>
                <p className={styles.priceFont}>$25.00</p>
                <a className={styles.button} target="_blank" data-url="https://checkout.square.site/buy/UTFNSTJMYLUADY6ZCBJS7C73" href="https://checkout.square.site/buy/UTFNSTJMYLUADY6ZCBJS7C73">Buy now</a>
            </div>
        </div>
    </div>
)}

export default MiniBoxBuyNow;